// Old version CommonDictionaryItemsAutocompleteServiceForDropdown src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/services/common_services_custom_dictionaries_module/common_dictionary_items_autocomplete_service_for_dropdown_service.js
// New version CommonBaseDictionaryService src/Common/controls/base-dictionary.service/common-base-dictionary.service.ts

import { HttpClient } from '@angular/common/http';
import { cloneDeep, each, defaults, keys, clone } from 'lodash';

import {
	CommonBaseListResourceService,
	ICommonListResourceService,
	ICommonListResourceServiceQueryData,
} from '../../base-list-resource.service/common-base-list-resource.service';
import {
	ICommonResponse,
} from '../../interfaces/response';
import {
	cancelRequestPolyfill,
	IPromiseWithCancel,
} from '../../utilities/core.service';
import { DateString, UUID } from '../../interfaces/core';
import { Injectable, Injector } from '@angular/core';
import { COMMON_HTTP_METHOD_TYPE } from '@CaseOne/Common/interfaces/common-http-method-type.enum';

export const COMMON_BASE_DICTIONARY_AUTOCOMPLETE_URL = 'api/dictionary/getdictionaryitems';

export interface ICommonBaseDictionaryItem {
	AuthorId: UUID,
	CreationDate: DateString,
	Id: UUID,
	IsCustom: boolean,
	IsLeaf: boolean,
	Items: ICommonBaseDictionaryItem[],
	LastChangeDate: DateString,
	LastModifedByUserId: UUID,  // TODO back-end mistake
	Level: number,
	Name: string,
	NextPageExists: boolean,
	Opened: boolean,
	Page: number,
	ParentId: UUID,
	SysName: string,
}

// From the class CommonBaseDictionaryService should be inherited services singletons (inRoot), which will cache the values of directories
@Injectable({
	providedIn: 'root',
})
export class CommonBaseDictionaryService<ItemType = ICommonBaseDictionaryItem> implements ICommonListResourceService {
	name: string = ''; // Use as SysName in request through default service
	isTree: boolean = false; // If true, will use `treeFormatter`
	useCache: boolean = true; // It true, service will getting items from cache
	srcService: ICommonListResourceService<ItemType>; // Custom service for getting items
	url: string = COMMON_BASE_DICTIONARY_AUTOCOMPLETE_URL; // Url for ListResourceService
	method: COMMON_HTTP_METHOD_TYPE;
	cacheFieldName = 'SystemName';

	private requestCacheMap: Map<string, IPromiseWithCancel<ICommonResponse<ItemType[]>>> = new Map();

	constructor(
		private httpClient: HttpClient,
		public injector: Injector,
	) {
		//
	}

	// Method for getting items list from server/cache
	get(data: ICommonListResourceServiceQueryData = {}): IPromiseWithCancel<ICommonResponse<ItemType[]>> {
		const sysName: string = data[this.cacheFieldName] || this.name;

		if (!sysName) {
			throw new Error(`sysName is not defined, set it as ${this.cacheFieldName} in query data or define "name" property of service`);
		}

		const isClearRequest = this.isClearRequest(data);
		const requestData = defaults({}, data, {
			[this.cacheFieldName]: sysName,
			Page: 1,
			PageSize: 100500,
		});
		let requestPromise: IPromiseWithCancel<ICommonResponse<ItemType[]>>;
		const formatFunctions = [
			this.formatter,
		];

		if (this.isTree) {
			formatFunctions.push(this.treeFormatter);
		}

		if (this.useCache && isClearRequest) {
			requestPromise = this.checkCache(sysName, requestData, formatFunctions);
		} else {
			this.initSrcService();
			requestPromise = this.srcService.get(requestData);
			requestPromise = this.applyFormatters(requestPromise, formatFunctions);
		}

		return requestPromise;
	}

	getDictionaries (names: string[]): Promise<Array<ICommonResponse<ItemType[]>>> {
		const promises = names.map((sysName) => {
			return this.get({
				[this.cacheFieldName]: sysName,
			});
		});

		return Promise.all(promises);
	}

	// Formatter for response from service
	formatter(response: ICommonResponse<ItemType[]>): ICommonResponse<ItemType[]> {
		return response;
	}

	checkCache<T extends ICommonResponse<ItemType[]>>(
		cacheKey: string,
		data: ICommonListResourceServiceQueryData,
		formatters: Array<(response: T) => T>,
	): IPromiseWithCancel<T> {
		let requestFromCache = this.requestCacheMap.get(cacheKey);
		let serviceRequest;

		if (!requestFromCache) {
			this.initSrcService();
			serviceRequest = this.srcService.get(data);
			requestFromCache = cancelRequestPolyfill(
				this.applyFormatters(serviceRequest, formatters)
					.catch((response) => {
						this.requestCacheMap.delete(cacheKey);
						return Promise.reject(response);
					}),
			);

			this.requestCacheMap.set(cacheKey, requestFromCache);
		}

		return cancelRequestPolyfill(
			requestFromCache
				.then((response: T) => {
					return cloneDeep(response);
				}),
		);
	}

	protected isClearRequest(data: ICommonListResourceServiceQueryData): boolean {
		const cloneData = clone(data);

		delete cloneData[this.cacheFieldName]; // it isnt important for caching

		if (cloneData.Name === '' || cloneData.Name === null) {
			delete cloneData.Name;
		}

		const dataKeys = keys(cloneData);
		const dontHaveFields = dataKeys.length === 0;
		const hasDefaultPaging = dataKeys.length === 2 && cloneData.Page === 1 && cloneData.PageSize === 100500;

		return dontHaveFields || hasDefaultPaging;
	}

	// Unwraps tree
	private treeFormatter<T extends {[key: string]: any, Result: any}> (response: T): T {
		const items = [];

		each(response.Result, (group) => {
			each(group.Items, (item) => {
				item.Group = group;
				items.push(item);
			});

			delete group.Items;
		});
		response.Result = items;

		return response;
	}

	private applyFormatters<T extends ICommonResponse<ItemType[]>>(
		requestPromise: IPromiseWithCancel<T>,
		formatters: Array<(response: T) => T>,
	): IPromiseWithCancel<T>  {
		each(formatters, (formatter) => {
			requestPromise = cancelRequestPolyfill(requestPromise.then(formatter));
		});

		return requestPromise;
	}

	private initSrcService() {
		if (!this.srcService) {
			this.srcService = new CommonBaseListResourceService(this.httpClient);
			(this.srcService as CommonBaseListResourceService).setConfig({
				url: this.url,
				method: this.method,
			});
		}
	}
}
