import { Injectable } from '@angular/core';
import { CommonBaseDictionaryService } from '../controls/base-dictionary.service/common-base-dictionary.service';
import {
	ICommonListResourceServiceQueryData
} from '@CaseOne/Common/base-list-resource.service/common-base-list-resource.service';
import { cancelRequestPolyfill, IPromiseWithCancel } from '@CaseOne/Common/utilities/core.service';
import { ICommonResponse } from '@CaseOne/Common/interfaces/response';
import { ICommonSysNameModel, UUID } from '@CaseOne/Common/interfaces/core';
import { commonConstants } from '../constants/common.constants';


export const COMMON_MAX_COUNT_ZEROS = 6;


@Injectable({
	providedIn: 'root',
})
export class CommonCountZerosDictionaryService extends CommonBaseDictionaryService<ICommonSysNameModel<number>> {
	name = 'CommonCountZerosDictionaryService';

	getDefaultValue(): ICommonSysNameModel<number> {
		return {
			Id: commonConstants.defaultNumberDigitsAfterDecimal.toString() as UUID,
			Name: commonConstants.defaultNumberDigitsAfterDecimal.toString(),
			SysName: commonConstants.defaultNumberDigitsAfterDecimal,
		};
	}

	getValues(): ICommonSysNameModel<number>[] {
		const result = [];
		let i = 0;

		while (i <= COMMON_MAX_COUNT_ZEROS) {
			result.push({
				Id: i.toString() as UUID,
				Name: i.toString(),
				SysName: i,
			});

			i++;
		}

		return result;
	}

	get(data: ICommonListResourceServiceQueryData = {}): IPromiseWithCancel<ICommonResponse<ICommonSysNameModel<number>[]>> {
		const promise = Promise.resolve()
			.then(() => {
				return {
					Result: this.getValues(),
					IsSuccess: true,
					Page: 1,
					PageSize: 100500,
				} as ICommonResponse;
			});

		return cancelRequestPolyfill(promise);
	}
}
