// New version src/Common/interceptors/error_interceptor.service.ts CommonErrorInterceptor
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/common_errors_interceptor_factory.js CommonErrorsInterceptor

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { get, includes, map as _map} from 'lodash';

import { CommonNotificationService } from '../notification/notification.service';
import { CommonModulesPlaceholders } from '../modules_placeholders/modules_placeholders.service';
import { CommonLocaleService } from '../locale/locale.service';
import { ICommonResponse, ICommonResponseError } from '../interfaces/response';


@Injectable()
export class CommonErrorInterceptor implements HttpInterceptor {
	private warningTypeConfigs;
	private warningTypes;

	constructor(
		private commonNotificationService: CommonNotificationService,
		private commonModulesPlaceholders: CommonModulesPlaceholders,
		private commonLocaleService: CommonLocaleService,
	) {}

	intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const isIgnoreNotification = req.headers.get('X-Ignore-Warnings');
		const cloneReq: HttpRequest<any> = req.clone({
			headers: req.headers.delete('X-Ignore-Warnings'),
		});

		return next.handle(cloneReq).pipe(
			map((response: ICommonResponse<any>) => {
				const warningText = get(response, 'body.Warning') || response.Warning;
				let warningHeader = get(response, 'body.WarningHeader') || response.WarningHeader;
				let warningType = get(response, 'body.WarningType') || response.WarningType;

				if (!isIgnoreNotification && (warningHeader || warningText || warningType)) {
					if (!this.warningTypeConfigs) {
						this.warningTypeConfigs = this.commonModulesPlaceholders.getPlaceholder('common-warning-types');
						this.warningTypes = _map(this.warningTypeConfigs, 'type');
						this.warningTypes.push('Success');
					}

					if (warningType === 'OperationIsFullСomplete') {
						warningType = 'Success';
					}

					if (warningType === 'OperationIsNotFullСomplete') {
						warningType = 'warning';
					}

					if (!includes(this.warningTypes, warningType)) {
						warningType = 'warning';
					}

					// FIXME providers is undefined inside TrustAccountInsufficientFunds warningTypeConfig (AngularJS code)
					// warningConfig = (get(find(this.warningTypeConfigs, { type: 'TrustAccountInsufficientFunds'}), 'getConfig') || noop)();

					warningHeader = warningHeader || this.commonLocaleService.instant('common.noty.warning.title');
					warningType = (warningType || '').toLowerCase();
					this.commonNotificationService.show(warningHeader, warningText, warningType);
				}

				return response;
			}),
			catchError((response: ICommonResponseError) => {

				// TODO 404 used with custom error messages
				if (response.status !== -1 && response.status !== 404 && (response.status < 200 || response.status > 299)) {
					this.commonNotificationService.handleResponseError(response);
				}

				return throwError(response);
			}),
		);
  }
}
