import { Injectable } from '@angular/core';
import * as InactiveJS from 'inactivejs';
import { CommonBaseService } from '@CaseOne/Common/common-base-service.class/common-base-service.class';
import { CommonAppDataService } from '@CaseOne/Common/app_data/app_data.service';
import { CommonCheckInactiveResourceService } from '@CaseOne/Common/common-check-inactive/services/common-check-inactive-resource.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class CommonCheckInactiveService extends CommonBaseService {
	private commonAppDataService = this.injector.get(CommonAppDataService);
	private commonCheckInactiveResourceService = this.injector.get(CommonCheckInactiveResourceService);

	private pingPeriod: number = this.commonAppDataService.getData().PingPeriod;
	private timeoutForClear: NodeJS.Timeout;
	private isActive: boolean = true;  // indicate that user is active in browser
	private isWaitingCheckStatus: boolean = false;  // indicate that client is waiting next check status request
	private inactiveInstance: InactiveJS;

	start () {
		if (this.isActiveService()) {
			this.inactiveInstance = new InactiveJS({
				timeout: this.pingPeriod,
				onAway: () => {
					this.onAway();
				},
				onBack: () => {
					this.onBack();
				},
			});
			this.startTimer(this.pingPeriod);
		}
	}

	stop() {
		this.stopTimer();

		if (this.inactiveInstance) {
			this.inactiveInstance.stop();
		}
	}

	private startTimer(timeout: number) {
		this.stopTimer();
		this.timeoutForClear = setTimeout(() => {
			if (this.isActive) {
				this.startPing();
			} else {
				this.checkSession();
			}
		}, timeout);
	}

	private stopTimer() {
		if (this.timeoutForClear) {
			clearTimeout(this.timeoutForClear);
			this.timeoutForClear = null;
		}
	}

	private ping(): Observable<void> {
		this.isWaitingCheckStatus = false;

		return this.commonCheckInactiveResourceService.ping()
			.pipe(
				catchError((error) => {
					this.stop();
					return throwError(error);
				}),
			);
	}

	private startPing() {
		this.ping()
			.subscribe(() => {
				this.startTimer(this.pingPeriod);
			});
	}

	private checkSession() {
		this.isWaitingCheckStatus = true;

		this.commonCheckInactiveResourceService.checkSession()
			.pipe(
				catchError((error) => {
					this.stop();
					return throwError(error);
				}),
			)
			.subscribe((response) => {
				this.startTimer(response.Result.TimeLeft);
			});
	}

	private onAway() {
		this.isActive = false;
	}

	private onBack() {
		this.isActive = true;

		if (this.isWaitingCheckStatus) {
			this.startPing();
		}
	}

	private isActiveService(): boolean {
		return this.commonAppDataService.getData()?.PingPeriod > 0;
	}
}
